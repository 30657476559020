import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Select } from 'antd';
import uiSettings, { htmlTable } from "../../utils/uiSettings";
import henceforthApi from "../../utils/henceforthApi";
import { GlobalContext, handleError } from "../../context/Provider";
import { goBack } from "../../utils/CommonFunctions";
import HenceforthGoogleMap from "./HenceforthGoogleMap";
import { Button, Popover } from 'antd';
import { useNavigate } from "react-router-dom";
import { Popconfirm } from 'antd';
import resetIcon from '../../assets/icons/Location-Points.svg'
import { toast } from "react-toastify";

var userMarker: Array<google.maps.Marker> = [];
var previewClearwayPolyline: any;
var gLoading = false
const defaultDay = 1
const ClearWyZoneAdd = () => {

  const defaultProps = {
    center: {
      lat: -37.82168417163056,
      lng: 145.05854992101723
    },
    zoom: 12
  };
  var uniqueId = 1;
  const navigate = useNavigate()
  const { authState, Toast } = useContext(GlobalContext)
  henceforthApi.setToken(authState.access_token)
  const googleMapRef = useRef(null as any)
  const googlePreviewMapRef = useRef(null as any)
  const [previewZoom, setPreviewZoom] = useState(defaultProps.zoom)
  const [previewCenter, setPreviewCenter] = useState(defaultProps.center)
  const [onAddressChanged, setOnAddressChanged] = useState(defaultProps)
  const [showGoogleAddressInput, setShowGoogleAddressInput] = useState(false)
  const [showPreview, setShowPreview] = useState(false)

  const [name, setName] = useState("")
  const [location_address, setLocationAddress] = useState("")
  const [daysOf, setDaysOf] = useState('1')
  const [state, setState] = useState('1')
  const [city, setCity] = useState('1')
  const [message, setMessage] = useState("")
  const [type, setType] = useState("Tow-away zone")
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false);
  const locationSearchRef = useRef() as any
  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const createMerker = (position: google.maps.LatLng | google.maps.LatLngLiteral, map: google.maps.Map, icon?: any) => {
    return new google.maps.Marker({
      position,
      map,
      draggable: false,
      animation: google.maps.Animation.DROP,
      label: { text: `${userMarker.length + 1}`, color: '#FFFFFF' },
      icon: icon,
    });
  }
  createMerker.id = uniqueId;
  uniqueId++;

  function setMapOnAll(map: google.maps.Map | null) {
    for (let i = 0; i < userMarker.length; i++) {
      userMarker[i].setMap(map);
    }
  }

  const removeStateData = async () => {
    setTimeout(() => {
      userMarker = []
      gLoading = false
      setName("")
      setDaysOf('')
      locationSearchRef.current.value = ""
      setMessage("")
      setType("")
      setLoading(gLoading)

    }, 2000)
    setMapOnAll(null)
  }

  const selectedMarkersShow = async ({ id, name, location, days_name, timezone, start_time, end_time, description, locations }: any, googleMapRef: any) => {

    const stepDisplay = new google.maps.InfoWindow;
    const path = locations.map((res: any) => (
      { lat: Number(res.lat), lng: Number(res.lng) })
    );
    const clearwayPolyline = new google.maps.Polyline({
      path,
      geodesic: true,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
    let days = uiSettings.WeekName.find(res => res.value === days_name[0])?.label
    const info = {
      path,
      days,
      proposed: description,
    } as any
    clearwayPolyline.setMap(googleMapRef.current.map_);
    google.maps.event.addListener(clearwayPolyline, 'click', (evt: any) => {
      stepDisplay.setContent(htmlTable(info));
      stepDisplay.setPosition(evt.latLng);
      stepDisplay.open(googleMapRef.current.map_);
    })
    return clearwayPolyline
  }

  const handleAddPreviewMap = async (select: string, googlePreviewMapRef: any) => {
    // debugger
    if (select !== 'Add') {
      setShowPreview(true)
    }
    try {
      const directionsService = new google.maps.DirectionsService();
      let locationMarker = []
      let originMarker = userMarker[0]
      let destinationMarker = userMarker[userMarker.length - 1]
      for (let index = 1; index <= userMarker.length - 2; index++) {
        const element = userMarker[index];
        locationMarker.push({ location: { lat: element.getPosition()?.lat(), lng: element.getPosition()?.lng() }, stopover: true })
      }

      var request = {
        origin: { lat: originMarker.getPosition()?.lat() as number, lng: originMarker.getPosition()?.lng() as number },
        destination: { lat: destinationMarker.getPosition()?.lat() as number, lng: destinationMarker.getPosition()?.lng() as number },
        travelMode: google.maps.TravelMode.DRIVING
      } as any;
      if (locationMarker.length) {
        request['waypoints'] = locationMarker;
      }

      directionsService.route(request as any, async (response: any, status: any) => {
        if (status == 'OK') {
          const myroute = (response as google.maps.DirectionsResult).routes[0];
          const clearwayCoordinates = myroute?.overview_path.map(res => { return { lat: res.lat(), lng: res.lng() } })
          let items = {
            name: name,
            location: location_address,
            description: message,
            locations: clearwayCoordinates,
            // days: [daysOf]
            days: [defaultDay]
          }
          let info = {
            name: name,
            location: location_address,
            description: message,
            locations: clearwayCoordinates,
            // days_name: [daysOf]
            days_name: [defaultDay]
          }
          let data = await getLocationName(userMarker[0]) as {}
          if (select === 'Add') {
            if (clearwayCoordinates.length) {
              gLoading = true
              setLoading(gLoading)
              const apiRes = await henceforthApi.ClearWayaZone.add({ ...items, ...data })
              Toast.success(apiRes.message)
              await removeStateData()
              navigate(`/clearwayzone/${apiRes?.data?.id}/view`)
            } else {
              Toast.warning("Please insert way of markers")
            }
          }
          else {
            if (clearwayCoordinates.length) {
              if (previewClearwayPolyline) {
                previewClearwayPolyline?.setMap(null)
              }
              const clearwayPolyline = await selectedMarkersShow(info, googlePreviewMapRef)
              previewClearwayPolyline = clearwayPolyline
            }
          }
        }
      });
    } catch (error: any) {
      // console.log(error.response.body.message)
      toast.warn('Please select your path!')
      console.log('hello')
    } finally {
      gLoading = false
      setLoading(gLoading)
    }
  }
  let infowindow: any;

  const addMarkerByMapEvent = (position: google.maps.LatLng | google.maps.LatLngLiteral, map: google.maps.Map) => {
    let marker = createMerker(position, map)
    let oldMapMarker = userMarker
    oldMapMarker.push(marker)
    userMarker = [...oldMapMarker]
    // marker.addListener('dragend', (e: any) => {
    //   console.log(e.latLng.lat());
    // });
    const lastIndex = userMarker.length - 1

    // create a popover element for remove last index icon 
    const divHtml = document.createElement("div")
    divHtml.className = "d-flex flex-column shadow text-danger"
    const paragraphHtml = document.createElement("p")
    paragraphHtml.innerHTML = "Do you want to remove?"
    const buttonHtml = document.createElement("button")
    buttonHtml.className = "btn btn-primary mt-2"
    buttonHtml.innerHTML = "Yes"


    // Add listener to that element to remove last icon 
    buttonHtml.addEventListener("click", (e) => {
      console.log(e);
      console.log('lastIndex', lastIndex);
      if (lastIndex === lastIndex) {
        console.log('clicked');
        const removeValue = userMarker.pop()
        const removeMarker = (removeValue as any).setMap(null)
        if (userMarker.length !== 0) {
          handleAddPreviewMap('Prev', googlePreviewMapRef)
        }
      }
      if (userMarker.length === 0) {
        setShowPreview(false)
      }
    })

    // Appending that element to DOM
    divHtml.appendChild(paragraphHtml)
    divHtml.appendChild(buttonHtml)


    // creating a popover window 
    infowindow = new google.maps.InfoWindow({
      content: divHtml,
    });

    // create a listener for click event on map icons
    marker.addListener('click', (e: any) => {
      const lastIndex1 = userMarker.length - 1
      if (lastIndex === lastIndex1) {
        infowindow.open(map, marker);
        // const removeValue = userMarker.pop()
        // const removeMarker = (removeValue as any).setMap(null)
      }
      if (userMarker.length === 0) {
        setShowPreview(false)
      }
    });
  }

  const getLocationName = async (array: any) => {

    return await new Promise((resolve, reject) => {

      let address: any
      try {
        let latlng = new (window as any).google.maps.LatLng(
          array.position.lat(),
          array.position.lng()
        )
        var geocoder = new (window as any).google.maps.Geocoder()
        geocoder.geocode({ latLng: latlng }, async (results: any, status: any) => {
          address = results[0].address_components
          let administrativeAreaIndex = address.findIndex((res: any) => res.types.includes("administrative_area_level_1", "political"))
          let localityIndex = address.findIndex((res: any) => res.types.includes("locality", "political"))
          let countryIndex = address.findIndex((res: any) => res.types.includes("country", "political"))
          resolve({ country: address[countryIndex].long_name, state: address[administrativeAreaIndex].long_name, city: address[localityIndex].long_name })
        })
      } catch (err) {
        reject(err)
      }
    })

  }

  const onGoogleApiLoaded = ({ map, maps, ref }: any) => {
    setShowGoogleAddressInput(true)

    map.addListener("click", (event: google.maps.MapMouseEvent) => {
      if (!gLoading) {
        addMarkerByMapEvent(event.latLng!, map);
        handleAddPreviewMap('Prev', googlePreviewMapRef)

      }
    });
    initPlaceAPI()
  }

  const initPlaceAPI = () => {
    if (locationSearchRef) {

      const southwest = { lat: 5.6108, lng: 136.589326 };
      const northeast = { lat: 61.179287, lng: 2.64325 };
      const newBounds = new google.maps.LatLngBounds(southwest, northeast);

      var options = {
        componentRestrictions: { country: ['in', 'au'] }
      };


      let autocomplete = new (window as any).google.maps.places.Autocomplete(
        locationSearchRef.current, options
      );

      // autocomplete.setTypes("changetype-cities", ["(cities)"])
      // autocomplete.setBounds(newBounds);


      autocomplete.addListener('place_changed', () => {
        let place = autocomplete.getPlace();
        let formatAddress = place.formatted_address
        const address = place.address_components
        let items: any = {}
        if (Array.isArray(address) && address.length > 0) {
          let zipIndex = address.findIndex(res => res.types.includes("postal_code"))
          let administrativeAreaIndex = address.findIndex(res => res.types.includes("administrative_area_level_1", "political"))
          let localityIndex = address.findIndex(res => res.types.includes("locality", "political"))
          let countryIndex = address.findIndex(res => res.types.includes("country", "political"))
          let premiseIndex = address.findIndex(res => res.types.includes("premise", "street_number"))
          let sublocality1 = address.findIndex(res => res.types.includes('sublocality_level_1', 'sublocality', 'political'))
          let sublocality2 = address.findIndex(res => res.types.includes('sublocality_level_2', 'sublocality', 'political'))
          let route = address.findIndex(res => res.types.includes('route'))
          let subpremise = address.findIndex(res => res.types.includes('subpremise'))
          let street_number = address.findIndex(res => res.types.includes('street_number'))
          if (zipIndex > -1) {
            items.pin_code = address[zipIndex]?.long_name
          }
          if (administrativeAreaIndex > -1) {
            items.state = address[administrativeAreaIndex]?.long_name
          }
          if (localityIndex > -1) {
            items.city = address[localityIndex]?.long_name
          }
          if (countryIndex > -1) {
            items.country = address[countryIndex]?.long_name
          }
          if (premiseIndex > -1) {
            items.apartment_number = address[premiseIndex]?.long_name
          }
          items.full_address = formatAddress
          items.sublocality1 = address[sublocality1]?.long_name
          items.sublocality2 = address[sublocality2]?.long_name
          items.subpremise = address[subpremise]?.long_name
          items.route = address[route]?.long_name
          items.street_number = address[street_number]?.long_name
          let zoom = 12
          if (items?.country && items?.state && items?.city && items?.sublocality1 && (items?.sublocality2 || items?.route) && (items?.subpremise || items?.street_number)) zoom = 18
          if (items?.country && items?.state && items?.city && items?.sublocality1 && (items?.sublocality2 || items?.route) && items?.subpremise === undefined && items?.street_number === undefined) zoom = 18
          if (items?.country && items?.state && items?.city && items?.sublocality1 === undefined && items?.sublocality2 === undefined) zoom = 15
          if (items?.country && items?.state && items?.city === undefined && items?.sublocality1 === undefined && items?.sublocality2 === undefined) zoom = 8
          if (items?.country && items?.state === undefined && items?.city === undefined && items?.sublocality1 === undefined && items?.sublocality2 === undefined) zoom = 5
          setLocationAddress(items?.full_address ? items?.full_address : `${items?.city},${items?.state},${items?.country}`)
          setOnAddressChanged((onAddressChanged: any) => {
            return {
              ...onAddressChanged,
              center: {
                ...onAddressChanged.center,
                lat: place?.geometry?.location?.lat(),
                lng: place?.geometry?.location?.lng(),
              },
              zoom: zoom
            }
          })
          setPreviewCenter((state: any) => {
            return {
              ...state,
              lat: place?.geometry?.location?.lat(),
              lng: place?.geometry?.location?.lng(),
            }
          })
          setPreviewZoom(11)
          setMapOnAll(null)
          userMarker = []
        }

      }
      );
    }
  }

  const initialise = async () => {
    try {
      const apiRes = await henceforthApi.ClearWayaZone.pagination('', '', '')
      const apiData = apiRes.data
      const { data } = apiData
      if (Array.isArray(data)) {
        data.forEach((res) => {
          selectedMarkersShow(res, googleMapRef)
        })
      }
    } catch (error) {

    }
  }

  let value = ''
  if (state.includes('1')) value = 'Sydney'
  if (state.includes('2')) value = 'Brisbane'
  if (state.includes('3')) value = 'Adelaide'
  if (state.includes('4')) value = 'Hobart'
  if (state.includes('5')) value = 'Melbourne'
  if (state.includes('6')) value = 'Perth'

  let cityoptions: any = {};
  if (state === '1') cityoptions = uiSettings.CityNames.NewSouthWales;
  if (state === '2') cityoptions = uiSettings.CityNames.Queensland;
  if (state === '3') cityoptions = uiSettings.CityNames.SouthAustralia;
  if (state === '4') cityoptions = uiSettings.CityNames.Tasmania;
  if (state === '5') cityoptions = uiSettings.CityNames.Victoria;
  if (state === '6') cityoptions = uiSettings.CityNames.WesternAustralia;

  // if (state === '1') cityoptions = uiSettings.CityNames.NewSouthWales, value = 'Sydney';
  // if (state === '2') cityoptions = uiSettings.CityNames.Queensland, value = 'Brisbane';
  // if (state === '3') cityoptions = uiSettings.CityNames.SouthAustralia, value = 'Adelaide';
  // if (state === '4') cityoptions = uiSettings.CityNames.Tasmania, value = 'Hobart';
  // if (state === '5') cityoptions = uiSettings.CityNames.Victoria, value = 'Melbourne';
  // if (state === '6') cityoptions = uiSettings.CityNames.WesternAustralia, value = 'Perth';

  React.useEffect(() => {
    if (showGoogleAddressInput) {
      initialise()
    }
    userMarker = []

    return () => {
      userMarker = []
    }
  }, [showGoogleAddressInput])



  return (
    <div className="container-fluid px-0">

      <div className="add-clearway-header bg-white py-4 px-4 border-bottom">
        <h3>Add Clearwayzone</h3>
      </div>

      <div className="add-clearway-content px-3">
        <div className="row gy-4 py-4 mx-0">
          <div className="col-md-6">

            <div className="add-clearway-form bg-white py-3 px-2 rounded h-100 border">
              <form onSubmit={(e) => { e.preventDefault(); }} className='px-3'>
                <div className="mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Name</label>
                  <div className="input-group mb-3" id="exampleFormControlInput1">
                    <input type="text" className="form-control border" placeholder="Name" name="name" value={name} onChange={(e) => setName(e.target.value)} required />
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Select State</label>
                  <div className="input-group mb-3" id="exampleFormControlInput1">
                    <Select
                      className="form-control py-2"
                      style={{ width: '100%' }}
                      placeholder="Please select day"
                      value={state}
                      onChange={setState}
                      options={uiSettings.StateName}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Select City</label>
                  <div className="input-group mb-3" id="exampleFormControlInput1">
                    <Select
                      className="form-control py-2"
                      style={{ width: '100%' }}
                      placeholder="Please select day"
                      value={value}
                      onChange={setCity}
                      options={cityoptions}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Location</label>
                  <div className="input-group mb-3" id="exampleFormControlInput1">
                    <input type="text" ref={locationSearchRef} className="form-control border" placeholder="Location" name="location_address" />
                  </div>
                </div>
                {/* <div className="mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Select Day</label>
                  <div className="input-group mb-3" id="exampleFormControlInput1">
                    <Select
                      className="form-control py-2"
                      style={{ width: '100%' }}
                      placeholder="Please select day"
                      value={daysOf}
                      onChange={setDaysOf}
                      options={uiSettings.WeekName}
                    />
                  </div>
                </div> */}
                {/* <div className="mb-4 pb-2">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Message (optional)</label>
                  <textarea className="form-control border" id="floatingTextarea2" placeholder="Please write here message" style={{ height: '100px' }} name="message" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                </div> */}

                <div className="">
                  <ul className="list-unstyled d-flex flex-wrap gap-3 align-items-center">
                    <li>
                      <Popover
                        content={
                          <span>
                            <a className="text-decoration-none text-danger" onClick={() => { removeStateData(); goBack(); }}>Yes</a>
                            <a className="text-decoration-none text-black ms-2" onClick={() => { hide(); }}>No</a>
                          </span>

                        }
                        title="Do you want to cancel Adding Clearway?"
                        trigger="click"
                        open={open}
                        onOpenChange={handleOpenChange}>
                        <Button type="primary" value='large' className='btn btn-theme' htmlType="button" style={{ height: '40px' }}>
                          Cancel
                        </Button>
                      </Popover>

                    </li>
                    <li>
                      <Button type="primary" value='large' onClick={() => handleAddPreviewMap('Add', googlePreviewMapRef)} loading={loading} className='btn btn-primary' disabled={!(name && location_address)} htmlType="button" style={{ height: '40px' }}
                      >
                        <span className='align-middle'>Add Clearway </span>
                      </Button>
                    </li>
                    {showPreview &&
                      <li className="ms-auto">
                        <Popconfirm
                          title="Delete the location"
                          description="Are you sure to reset this location?"
                          okText="Yes"
                          okType="danger"
                          cancelText="No"
                          onConfirm={() => {
                            setMapOnAll(null)
                            userMarker = []
                            previewClearwayPolyline?.setMap(null);
                            setShowPreview(false)
                          }}

                        >
                          <a href="#" className="nav-link fw-500 text-primary">
                            <img src={resetIcon} alt="icon" />
                            <span className="ms-2">Reset Pin Locations </span>
                          </a>
                        </Popconfirm>
                      </li>}
                    {showPreview &&
                      <li className="">
                        <Popconfirm
                          title="Delete the location"
                          description="Are you sure to reset last location?"
                          okText="Yes"
                          okType="danger"
                          cancelText="No"
                          onConfirm={() => {
                            const removeValue = userMarker.pop()
                            const removeMarker = (removeValue as any).setMap(null)
                            if (userMarker.length === 0) {
                              setShowPreview(false)
                            }
                            if (userMarker.length !== 0) {
                              handleAddPreviewMap('Prev', googlePreviewMapRef)
                            }
                          }}
                        >
                          <a href="#" className="nav-link fw-500 text-primary btn-outline-danger p-2 rounded-3">
                            <img src={resetIcon} alt="icon" />
                            <span className="ms-2">Remove last pin</span>
                          </a>
                        </Popconfirm>
                      </li>}
                  </ul>
                </div>

              </form>
            </div>

          </div>

          <div className="col-md-6">

            <div style={{ height: "100vh", width: "100%" }} className='border'>
              <HenceforthGoogleMap
                ref={googleMapRef}
                defaultCenter={defaultProps.center}
                center={onAddressChanged.center}
                zoom={onAddressChanged.zoom}
                defaultZoom={defaultProps.zoom}
                onZoomAnimationEnd={(e: any) => {
                  setPreviewZoom(e)
                }}
                onDrag={(e: any) => {
                  setPreviewCenter({
                    lat: e.center.lat(),
                    lng: e.center.lng()
                  })

                }}
                onGoogleApiLoaded={useMemo(() => onGoogleApiLoaded, [])} />
            </div>
          </div>
        </div>

        <div className="accordion px-2 pb-4" id="accordionExample" style={{ display: showPreview ? 'block' : "none" }}>
          <div className="accordion-item border">
            <h2 className="accordion-header" id="headingOne">
              <button className="accordion-button bg-white text-black shadow-none pb-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                <h3> Clearway Preview</h3>
              </button>
            </h2>
            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
              <div className="accordion-body bg-white">
                <div style={{ height: "350px", width: "100%" }}>
                  <HenceforthGoogleMap
                    ref={googlePreviewMapRef}
                    center={previewCenter}
                    zoom={previewZoom}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClearWyZoneAdd;
